import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Button, CircularProgress } from "@material-ui/core";
import Layout from "../../components/shared-layout";
import useStores from "../../hooks/use-stores";
import { observer } from 'mobx-react';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { useTranslation } from 'react-i18next';

const ParentModulePage = observer(() => {
  const { parentStore, rootStore } = useStores();
  const { t } = useTranslation();

  useEffect(() => {
    parentStore.getParentPortalModuleList();
  }, []);

  const onChangeModuleDisplay = (id: number, display: boolean) => {
    parentStore.updateParentPortalModuleDisplay(id, display)
      .then(() => {
        parentStore.getParentPortalModuleList();
        rootStore.notify(`${t('MODULE')} ID: ${id} ${t('DISPLAY_STATUS_UPDATED')}!`, 'success');
      })
  }

  return (
    <>
      {
        typeof window !== "undefined" &&
        <Layout
          showSeachStudent={true}
          showStaffList={false}
          showYearSemTerm={true}
          showFilter={false}
          showClassCode={false}
          showYearRange={false}
          showDomain={false}
          showNewsArea={true}
        >
          <title>{t('PARENT_MODULE')}</title>
          <div className="col-span-4">
            <div className="m-2 p-4 shadow-lg rounded-xl border">
              <div className="px-4 text-barPrimary text-xl font-bold grid grid-cols-2 mb-8">
                <div className="py-2 uppercase">{t('PARENT_MODULE')}</div>
              </div>
              {
                parentStore.loading ? <div className="col-span-3 w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
                  :
                  <table width="100%">
                    <thead className="bg-barPrimary text-white text-center">
                      <td className="border-r p-2">{t('MODULE')} ID</td>
                      <td className="border-r p-2">{t('MODULE_NAME')}</td>
                      <td className="border-r p-2">{t('DISPLAY_STATUS')}</td>
                      <td className="border-r p-2">{t('TOGGLE_DISPLAY')}</td>
                    </thead>
                    <tbody className="text-center">
                      {
                        parentStore.parentModuleList.length > 0 &&
                        parentStore.parentModuleList.map((module, index) => (
                          <tr key={`parent_module_data_${index}`} className={`${index % 2 != 0 && 'bg-gray-50'}`}>
                            <td className="p-2">{module.id}</td>
                            <td className="p-2">{module.moduleName}</td>
                            <td className="p-2 text-green-400">
                              {module.display ? <CheckIcon color={'inherit'} fontSize={'large'} /> : <ClearIcon color={'error'} fontSize={'large'}  />}
                            </td>
                            <td className="p-2">
                              <Button
                                className="bg-buttonPrimary text-white text-sm"
                                component="span"
                                onClick={() => onChangeModuleDisplay(module.id, !module.display)}
                              >
                                {!module.display ? t('TO_VISIBLE') : t('TO_HIDDEN')}
                              </Button>

                            </td>
                          </tr>
                        ))

                      }
                    </tbody>
                  </table>
              }
            </div>
          </div>
        </Layout>
      }

    </>
  )
})

export default ParentModulePage;
